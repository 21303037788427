/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Link } from 'react-router-dom';

import STATUSES from './statuses';
import { SORTING_TYPES, search, sort, translateStatus } from '<app>/utils/tableMethods';
import './style.scss';
import { auth } from '../../utils';

const LICENSE_FRONT = 'drivingLicenseFront';
const LICENSE_BACK = 'drivingLicenseBack';

const LICENSE_FIELDS = [LICENSE_BACK, LICENSE_FRONT];

const IGNORE_REDIRECT_COLUMNS = [
  'resetVehicle',
  'toggleLock',
  'deleteVehicle',
  'deleteUser',
  'assignedVehicle',
  LICENSE_FRONT,
  LICENSE_BACK,
];

function Table({
  keys,
  centeredKeys,
  allowSortByKeys,
  initialData,
  searchByKeys,
  onRedirect,
  selectable,
  onRowsSelected,
}) {
  const [data, setData] = useState(initialData);
  const [columnToSortBy, setColumnToSortBy] = useState();
  const [sortingIteration, setSortingIteration] = useState(SORTING_TYPES.DEFAULT);
  const [searchValue, setSearchValue] = useState('');
  const [selectedRows, setSelectedRows] = useState([]);

  const { accessToken } = auth.getAccessTokens();

  const resetTable = (currentKey) => {
    setSortingIteration(SORTING_TYPES.DEFAULT);
    if (currentKey) {
      setColumnToSortBy(currentKey);
      setSortingIteration(1);
    }
  };

  useEffect(() => {
    if (searchValue) {
      setData(initialData?.filter((dataRow) => search(searchValue, dataRow, searchByKeys)));
    }
    if (!searchValue) setData(initialData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  useEffect(() => {
    if (sortingIteration === SORTING_TYPES.DEFAULT) {
      if (searchValue) {
        setData(initialData?.filter((dataRow) => search(searchValue, dataRow, searchByKeys)));
      } else setData(initialData);
    } else setData(sort(data, sortingIteration, columnToSortBy));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortingIteration, columnToSortBy]);

  useEffect(() => {
    setData(initialData);
  }, [initialData]);

  useEffect(() => {
    if (onRowsSelected) {
      onRowsSelected(selectedRows);
    }
  }, [selectedRows, onRowsSelected]);

  return (
    <div className="shadow flex w-full flex-col border-b border-gray-200">
      {searchByKeys && (
        <input
          placeholder="Paieška"
          onChange={({ target: { value } }) => setSearchValue(value)}
          className="text-400 border border-black p-1 pl-3 rounded-200 mb-2 w-full sm:w-fit"
          value={searchValue}
        />
      )}
      <div className="overflow-x-auto">
        <table className="bg-white w-full">
          <thead className="bg-gray-100 text-white">
            <tr>
              {selectable && (
                <th className="py-3 px-4 uppercase font-semibold text-sm">
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      const selected = e.target.checked ? data.map((row) => row.id) : [];
                      setSelectedRows(selected);
                    }}
                  />
                </th>
              )}
              {Object.entries(keys).map((key, index) => {
                const currentKey = key[0];
                return (
                  <th
                    key={index}
                    onClick={
                      allowSortByKeys?.includes(currentKey)
                        ? () => {
                            if (currentKey !== columnToSortBy) {
                              resetTable(currentKey);
                            } else {
                              setColumnToSortBy(currentKey);
                              setSortingIteration(
                                sortingIteration === 2 ? 0 : sortingIteration + 1
                              );
                            }
                          }
                        : null
                    }
                    className={cx('text-left py-3 px-4 uppercase font-semibold text-sm relative', {
                      'cursor-pointer': allowSortByKeys?.includes(currentKey),
                      'text-center': centeredKeys?.includes(currentKey),
                    })}
                  >
                    {key[1]}
                    {allowSortByKeys?.includes(currentKey) && (
                      <div
                        className={cx(
                          'sort-by-arrows',
                          {
                            'sort-by-arrows--active-top':
                              columnToSortBy === currentKey &&
                              sortingIteration === SORTING_TYPES.ASCENDING,
                          },
                          {
                            'sort-by-arrows--active-bottom':
                              columnToSortBy === currentKey &&
                              sortingIteration === SORTING_TYPES.DESCENDING,
                          }
                        )}
                      />
                    )}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody className="text-black">
            {data?.map((row, index) => (
              <tr key={index} className={cx({ 'bg-gray-200': index % 2 === 0 })}>
                {selectable && (
                  <td className="py-3 px-4">
                    <input
                      type="checkbox"
                      checked={selectedRows.includes(row.id)}
                      onChange={() => {
                        const updatedSelectedRows = selectedRows.includes(row.id)
                          ? selectedRows.filter((id) => id !== row.id)
                          : [...selectedRows, row.id];
                        setSelectedRows(updatedSelectedRows);
                      }}
                    />
                  </td>
                )}
                {Object.keys(keys).map((key, keyIndex) => {
                  return (
                    <td
                      key={keyIndex}
                      className={cx('py-3 px-4', {
                        'text-center': centeredKeys?.includes(key),
                        'text-left': !centeredKeys?.includes(key),
                      })}
                      style={
                        key === 'rentDuration' || key === 'rentStartTime' || key === 'rentEndTime'
                          ? { whiteSpace: 'nowrap' }
                          : {}
                      }
                    >
                      {LICENSE_FIELDS.includes(key) && !!row[key] ? (
                        <a
                          href={`${row[key]}?auth=${accessToken}`}
                          rel="noreferrer"
                          target="_blank"
                          className="bg-blue-200 rounded-100 text-100 px-5 py-2 text-white "
                        >
                          Žiūrėti
                        </a>
                      ) : (
                        <>
                          {onRedirect && !IGNORE_REDIRECT_COLUMNS.includes(key) ? (
                            <Link to={`${onRedirect(row.id)}`} className="w-full block">
                              <Link to={`${onRedirect(row.id)}`} className="w-full block">
                                {translateStatus(row[key], key, STATUSES)}
                              </Link>
                            </Link>
                          ) : (
                            translateStatus(row[key], key, STATUSES) || '-'
                          )}
                        </>
                      )}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

Table.propTypes = {
  onRedirect: PropTypes.func,
  keys: PropTypes.shape().isRequired,
  allowSortByKeys: PropTypes.arrayOf(PropTypes.string),
  centeredKeys: PropTypes.arrayOf(PropTypes.string),
  initialData: PropTypes.arrayOf(PropTypes.shape).isRequired,
  searchByKeys: PropTypes.arrayOf(PropTypes.string),
  selectable: PropTypes.bool,
  onRowsSelected: PropTypes.func,
};

export default Table;
