import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation, useHistory } from 'react-router-dom';
import cx from 'classnames';

import pathnames from '<app>/pathnames';
import { useUserData } from '<app>/hooks';
import user from '<app>/models/user';

import './style.scss';

function SideBarLink({ to, label }) {
  const { pathname } = useLocation();

  return (
    <Link
      to={to}
      className={cx('flex items-center text-white py-4 hover:underline', {
        'opacity-75': pathname !== to,
      })}
    >
      {label}
    </Link>
  );
}

SideBarLink.propTypes = {
  to: PropTypes.string,
  label: PropTypes.string.isRequired,
};

function SideBar({ children }) {
  const { role, logout } = useUserData();

  const [isMenuCollapsed, setMenuCollapsed] = useState(true);
  const { pathname } = useLocation();
  const history = useHistory();
  useEffect(() => {
    setMenuCollapsed(true);
  }, [pathname]);

  return (
    <div className="flex flex-row h-full sidebar">
      <aside
        className={cx('shadow bg-blue-100 overflow-hidden h-full fixed z-10', {
          'sidebar-expand': !isMenuCollapsed,
          'sidebar-collapse': isMenuCollapsed,
        })}
      >
        <div className="flex ml-2 mt-4 justify-center items-start text-white">
          <button onClick={() => setMenuCollapsed((isCollapsed) => !isCollapsed)} type="button">
            {isMenuCollapsed ? '>>' : '<<'}
          </button>
        </div>
        <div className="flex ml-10 mt-20 text-white text-500 font-semibold uppercase">Admin</div>
        <nav className="text-white text-base font-semibold pt-3 px-10">
          {role === user.ROLE_VEHICLE_OWNER ? (
            <>
              <SideBarLink to={pathnames.VEHICLE_OWNER_AUTO_LIST} label="Automobilių sąrašas" />
              <SideBarLink to={pathnames.VEHICLE_OWNER_RENT_LIST} label="Nuomos sąrašas" />
              <SideBarLink to={pathnames.VEHICLE_OWNER_RESERVATION_LIST} label="Rezervacijos" />
            </>
          ) : (
            <>
              <SideBarLink to={pathnames.ADMIN_AUTO_LIST} label="Automobilių sąrašas" />
              <SideBarLink to={pathnames.ADMIN_RESERVATION_LIST} label="Rezervacijos" />
              <SideBarLink
                to={pathnames.ADMIN_PRICING_PLAN_LIST}
                label="Kainodaros planų sąrašas"
              />
              <SideBarLink to={pathnames.ADMIN_LOCATION_LIST} label="Lokacijų sąrašas" />
              <SideBarLink to={pathnames.ADMIN_CLIENT_LIST} label="Klientų sąrašas" />
              <SideBarLink to={pathnames.ADMIN_RENT_LIST} label="Nuomos sąrašas" />
              <SideBarLink to={pathnames.ADMIN_OWNERS_LIST} label="Sąvininkų sąrašas" />
              <SideBarLink to={pathnames.ADMIN_INVOICE_LIST} label="Sąskaitų sąrašas" />
            </>
          )}

          <button
            type="button"
            className="flex items-center text-white py-4 hover:underline opacity-75"
            onClick={() => {
              setMenuCollapsed(true);
              logout();
              history.push(pathnames.LOGIN);
              // TODO: send request to revoke tokens
            }}
          >
            Atsijungti
          </button>
        </nav>
      </aside>
      {children}
    </div>
  );
}

SideBar.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SideBar;
