const roundTo15Mins = (date) => {
  const minutes = date.getMinutes();
  const rounding = 15;
  const roundedMinutes = Math.ceil(minutes / rounding) * rounding;
  if (roundedMinutes === 60) {
    date.setHours(date.getHours() + 1);
    date.setMinutes(0);
  } else {
    date.setMinutes(roundedMinutes);
  }
  return date;
};
export default roundTo15Mins;
