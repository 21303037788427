import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Loader } from '<app>/components';

import { checkTextClassname } from '<app>/utils';

const BUTTON_TYPES = {
  purple: 'bg-purple-100 text-white',
  black: 'border-2 text-black border-black',
  gray: 'bg-gray-200 text-black',
  disabled: 'bg-gray-500 text-black',
  danger: 'bg-red-300 text-white',
  text: '',
};

function Button({
  type,
  styleType,
  children,
  className,
  onClick,
  resetDefaultClassNames,
  loading,
  disabled,
  loaderColorClass,
  ...restProps
}) {
  return (
    <button
      // eslint-disable-next-line react/button-has-type
      type={type}
      onClick={onClick}
      disabled={loading || disabled}
      className={cx(
        { 'rounded-100 w-full lg:w-36 py-1 focus:outline-none': !resetDefaultClassNames },
        BUTTON_TYPES[styleType],
        {
          'text-300': !checkTextClassname(className),
        },
        className
      )}
      {...restProps}
    >
      {loading ? <Loader color={loaderColorClass} /> : children}
    </button>
  );
}

Button.propTypes = {
  type: PropTypes.string.isRequired,
  styleType: PropTypes.oneOf(Object.keys(BUTTON_TYPES)),
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
  resetDefaultClassNames: PropTypes.bool,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  loaderColorClass: PropTypes.string,
};

Button.defaultProps = {
  styleType: 'purple',
  type: 'button',
  loading: false,
  disabled: false,
};

export default Button;
