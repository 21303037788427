import { parse } from 'date-fns';

export const parseDateAsUTC = (dateStr, format) => {
  // Parse the date in local time
  const localDate = parse(dateStr, format, new Date());
  // Convert to UTC by subtracting the timezone offset
  return new Date(localDate.getTime() - localDate.getTimezoneOffset() * 60000);
};

export const toLocalISOString = (date) => {
  const offset = date.getTimezoneOffset();
  const localDate = new Date(date.getTime() - offset * 60 * 1000);
  return localDate.toISOString().slice(0, -1); // Remove 'Z' to indicate it's not UTC
};
