const ADMIN_PREFIX = '/admin';
const VEHICLE_OWNER_PREFIX = '/owner';

export default {
  LANDING: '/zemelapis',
  LOGIN: '/prisijungti',
  CONTACTS: '/kontaktai',
  REGISTER: '/registruotis',
  RESERVATIONS: '/mano-rezervacijos',
  ACCOUNT: '/mano-duomenys',
  IDENTIFICATION: '/identifikacija',
  PASSWORD_CHANGE: '/slaptazodzio-keitimas',
  PAYMENT_CARDS: '/mokejimo-korteles',
  PASSWORD_RESTORE: '/slaptazodzio-atstatymas',
  PRIVACY_POLICY: '/privatumo-politika',
  RENT_SERVICES_TERMS: '/nuomos-paslaugu-taisykles',
  GDPR_TERMS: '/privatumo-politika.pdf',
  ABOUT_US: '/apie-mus',
  PRICES: '/kainos',
  OWNED_VEHICLES: '/musu-automobiliai',
  ADMIN_AUTO_LIST: `${ADMIN_PREFIX}/automobiliu-sarasas`,
  ADMIN_PRICING_PLAN_LIST: `${ADMIN_PREFIX}/kainodaros-sarasas`,
  ADMIN_LOCATION_LIST: `${ADMIN_PREFIX}/lokaciju-sarasas`,
  ADMIN_CLIENT_LIST: `${ADMIN_PREFIX}/klientu-sarasas`,
  ADMIN_RENT_LIST: `${ADMIN_PREFIX}/nuomos-sarasas`,
  ADMIN_OWNERS_LIST: `${ADMIN_PREFIX}/savininku-sarasas`,
  ADMIN_CREATE_OWNER: `${ADMIN_PREFIX}/sukurti-savininka`,
  ADMIN_CREATE_VEHICLE: `${ADMIN_PREFIX}/sukurti-automobili`,
  ADMIN_CREATE_PRICING_PLAN: `${ADMIN_PREFIX}/sukurti-kainodaros-plana`,
  ADMIN_CREATE_LOCATION: `${ADMIN_PREFIX}/sukurti-lokacija`,
  ADMIN_INVOICE_LIST: `${ADMIN_PREFIX}/saskaitu-sarasas`,
  ADMIN_RESERVATION_LIST: `${ADMIN_PREFIX}/rezervaciju-sarasas`,
  ADMIN_EDIT_LOCATION: (id) => `${ADMIN_PREFIX}/koreguoti-lokacija/${id}`,
  ADMIN_EDIT_CLIENT: (id) => `${ADMIN_PREFIX}/koreguoti-klienta/${id}`,
  ADMIN_EDIT_OWNER: (id) => `${ADMIN_PREFIX}/koreguoti-savininka/${id}`,
  ADMIN_EDIT_VEHICLE: (id) => `${ADMIN_PREFIX}/koreguoti-automobili/${id}`,
  ADMIN_PREFIX,
  VEHICLE_OWNER_AUTO_LIST: `${VEHICLE_OWNER_PREFIX}/automobiliu-sarasas`,
  VEHICLE_OWNER_EDIT_VEHICLE: (id) => `${VEHICLE_OWNER_PREFIX}/koreguoti-automobili/${id}`,
  VEHICLE_OWNER_RENT_LIST: `${VEHICLE_OWNER_PREFIX}/nuomos-sarasas`,
  VEHICLE_OWNER_RESERVATION_LIST: `${VEHICLE_OWNER_PREFIX}/rezervaciju-sarasas`,
  VEHICLE_OWNER_PREFIX,
};
